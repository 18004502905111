import React from "react";
import FitnessAndAcademyWrap from "./DanceClassesForLadiesInVikasPuri";
import wedding from "../../assets/images/wedding-dance-choreographer-in-delhi ladies-sangeet-choreographer-in-delhi best-wedding-choreographer-in-delhi top-10-wedding-dance-choreographer-in-delhi.png";
import fitness from "../../assets/images/fitness.png";

const FitnessClassesForLadiesInVikasPuri = () => {
  return (
    <div>
      <div>
        <FitnessAndAcademyWrap
          title="Wedding Choreography"
          text="Wedding choreography takes your special dance from a few practiced steps to a "
          text2="captivating performance that reflects your unique style and personality."
          img={wedding}
          reverse
        ></FitnessAndAcademyWrap>  
      </div>

      <div>
        <FitnessAndAcademyWrap
          img={fitness}
        ></FitnessAndAcademyWrap>
      </div>
    </div>
  );
};

export default FitnessClassesForLadiesInVikasPuri;
