import React from 'react'
import Wedding from './BestWeddingDanceChoreographerInDelhi'
import WrapperContainer from '../WrapperContainer/WrapperContainer'
import Footer from '../Footer/AerialClassesInDelhi'


function BestWeddingDanceChoreographerInDelhiNcr() {

  return (
    <div>
   
        <WrapperContainer>
<Wedding></Wedding>
</WrapperContainer>
<Footer></Footer>
    </div>
  )
}

export default BestWeddingDanceChoreographerInDelhiNcr