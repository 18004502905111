import React from "react";
import { FooterDanceData } from "../../DataForPage/dummyData";
import { FooterGymnasticsData } from "../../DataForPage/dummyData";
import { FooterNoreData } from "../../DataForPage/dummyData";
import { FooterCoursData } from "../../DataForPage/dummyData";
// import logo from "../../assets/images/logo.png";
// import googlePlay from "../../assets/images/google-play.svg";
// import appleStore from "../../assets/images/apple-store.svg";

const AerialClassesInDelhi = () => {
  const year = new Date().getFullYear();
  return (
    <div>
      <div className="sm:grid-cols-2 grid grid-cols-4 gap-0 items-center justify-items-center">
        <div className="sm:w-full sm:pl-8 h-64 mx-auto pl-24">
          {/* <div>
            <img className="h-10 cursor-pointer" src={logo} alt="logo"></img>
          </div> */}
          <div className="sm:pr-4 h-64">
          <h3 className="text-dark font-inter font-medium text-lg no-underline align-middle tracking-wide normal-case">
           <h1 className=" text-cyan-600">
           Courses
            </h1> 
          </h3>
          {FooterCoursData.map((item) => {
            return (
              <div key={item.id}>
                <p className="text-dark hover:text-blue-500 font-inter text-md font-normal cursor-pointer no-underline align-middle tracking-wide normal-case">
                  {item.text}
                </p>
              </div>
            );
          })}
        </div>
        </div>
        <div className="sm:pr-4 h-64">
          <h3 className="text-dark font-inter font-medium text-lg no-underline align-middle tracking-wide normal-case">
           <h1 className=" text-cyan-600">
           Dance Activities
            </h1> 
          </h3>
          {FooterDanceData.map((item) => {
            return (
              <div key={item.id}>
                <p className="text-dark hover:text-blue-500 font-inter text-md font-normal cursor-pointer no-underline align-middle tracking-wide normal-case">
                  {item.text}
                </p>
              </div>
            );
          })}
        </div>
        <div className="sm:pr-8 h-64">
          <h3 className="text-dark font-inter font-medium text-lg no-underline align-middle tracking-wide normal-case">
          <h1 className=" text-cyan-600">
          Gymnastics
            </h1> 
          </h3>
          {FooterGymnasticsData.map((item) => {
            return (
              <div key={item.id}>
                <p className="text-dark hover:text-blue-500 font-inter text-md font-normal cursor-pointer no-underline align-middle tracking-wide normal-case">
                  {item.text}
                </p>
              </div>
            );
          })}
        </div>
        <div className="sm:pl-8 h-64">
          <h3 className="text-dark font-inter font-medium text-lg no-underline align-middle tracking-wide normal-case">
          <h1 className=" text-cyan-600">
          More Activities
            </h1> 
          </h3>
          {FooterNoreData.map((item) => {
            return (
              <div key={item.id}>
                <p className="text-dark hover:text-blue-500 font-inter text-md font-normal cursor-pointer no-underline align-middle tracking-wide normal-case">
                  {item.text}
                </p>
              </div>
            );
          })}
        </div>
      </div>
      <div className="flex items-center justify-center">
        <hr className="sm:w-full h-px w-4/5 bg-gray-400 opacity-50 outline-none border-none" />
      </div>
      <div className="flex justify-around items-center py-6">
        <div>
          <p className="sm:text-sm text-dark pb-2 font-inter font-medium cursor-pointer no-underline align-middle tracking-wide normal-case">
          Aone
          </p>
        </div>
        <div>
          <p className="sm:text-xs text-dark pb-2 font-inter font-light cursor-pointer no-underline align-middle tracking-wide normal-case">
            Copyright  {year} page by Aryan Tongariya
          </p>
        </div>
      </div>
    </div>
  );
};

export default AerialClassesInDelhi;
