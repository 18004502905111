import React from "react";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import "./App.css";
import Home from "./Components/AllComponents/Home";
import ThemeContext from "./Components/ContextWrapper/ThemeContext";
import About from "./Components/AboutUs/BestDanceAcademyForKidsInVikasPuriJanakPuri";
import AryanTongariya from "./Components/aryan/AryanTongariya";

// import WeddingModal from "./Components/wedingModal/WeddingModal"
// import { SliderData } from "./Components/wedingModal/SliderData";
// import Wedding from "./Components/Wedding/Wedding";
import WeddinhWrapper from "./Components/Wedding/BestWeddingDanceChoreographerInDelhiNcr";
import ErrorPage from "./Components/error page/ErrorPage";

function App() {
  return (  
    <div>
         <ThemeContext>
        {/* <Home></Home> */}
        <BrowserRouter>
      <Routes>
      <Route path='/' element={ <Home/>} />
    <Route path='/BestDanceAcademyForKids' element={ <About/>} />
    <Route path='/error' element={ <ErrorPage/>} />
    <Route path='/aryanTongariya' element={ <AryanTongariya/>} />
    {/* <Route path='/wedding' element={<WeddingModal slides={SliderData}  /> } /> */}
    <Route path='/BestWeddingDanceChoreographerInDelhiNcr' element={<WeddinhWrapper/>} />
      </Routes>
      </BrowserRouter>
    </ThemeContext>

    </div>
  );
}

export default App;
