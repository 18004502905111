import React, { useContext } from "react";
import { motion } from 'framer-motion';
import ring from "../../assets/images/aerial.png";
import { Switch } from "@material-tailwind/react";
import { ThemeBgContext } from "../ContextWrapper/ThemeContext";
import { useNavigate } from "react-router-dom";
import natraj from "../../assets/images/Natraja.png"
// import { routers } from "../../DataForPage/dummyData";






const AerialGymnasticsAcademyInDelhi = ({ topRef }) => {
  const { theme, handleTheme } = useContext(ThemeBgContext);
  const navigate = useNavigate()

  const aboutClick=()=>{

    navigate('/BestDanceAcademyForKids')
}

const aryanClick=()=>{
  navigate('/aryanTongariya')
}

  return (
    <div ref={topRef} className="mx-auto  pt-16">
      <div className="sm:grid-cols-1 grid grid-cols-2 justify-items-center items-center">
        <div className="sm:w-full sm:pl-4 w-4/5 pl-24">
          <Switch
            label={theme === "light" ? "Switch to Dark" : "Switch to Light"}
            defaultChecked={theme === "dark" ? true : false}
            onClick={handleTheme}
          />
          
      

              <div className="textstructure ml-0 mt-10 px-50   ">
        {["A-one", "natraj", "academy"].map((item, index)=>{
            return  <div className="masker">
              <div className="w-fit flex ">
                {index === 1 && (
                <motion.div 
                initial={{ width: 0}}
                animate={{width: "9vw"}}
                transition={{ease: [0.76, 0, 0.24, 1], duration: 1}}
                 className='mr-2  w-[12vw] rounded-md h-[5.7vw] relative -top-[1vw] '>
                  <img src={natraj} alt="" />
                 </motion.div>)}
              <h1 className='flex '
              >
                <div className={
              theme === "light"
                ? "items-center uppercase mt-1 text-[9vw] leading-[7vw] tracking-tighter font-['Founders_Grotesk'] font-medium text-dark"
                : "items-center uppercase mt-1 text-[9vw] leading-[7vw] tracking-tighter font-['Founders_Grotesk'] font-medium text-white"
            }>

               {item} 
                </div>
                </h1>
            
              </div>
         
         </div>
        })}
         {/* <img
            className="sm:h-[75px] w-[275px] ml-auto mt-1 bg-no-repeat bg-center relative z-10 header rounded-xl"
            src={ring}
            alt="header-phone"
          ></img> */}
  {/* <div 
      className="pb-20">
              <button onClick={homeClick}  className="pushable  mt-10"> 
              <span  className="front">Home Page</span>
              </button>
          </div> */}
         
</div>
          <div className="mt-4">
            <p
              className={
                theme === "light" 
                  ? "sm:text-lg w-4/5 font-inter font-medium no-underline align-middle tracking-wide normal-case text-dark text-2xl"
                  : "sm:text-lg w-4/5 font-inter font-medium no-underline align-middle tracking-wide normal-case text-white text-2xl"
              }
            >{" "}
            <h1 className={
              theme === "light"
                ? "sm:text-3xl text-5xl font-russonOne font-medium no-underline align-middle tracking-wide normal-case leading-normal text-dark"
                : "sm:text-3xl text-5xl font-russonOne font-medium no-underline align-middle tracking-wide normal-case leading-normal text-white"
            }>Best Academy of Delhi</h1>
              We opened our door as Aone Natraj academy, in 2010 established by Mr. <p onClick={aryanClick} className=" hover:text-blue-300 cursor-pointer text-royalBlue underline">Aryan Kumar Tongariya & Suman Tongariya</p> 
            </p>
          </div>

        
              <div 
               className="pb-20">
              <button onClick={aboutClick} className="pushable mt-4">
              <span  className="front">About Us</span>
              </button>
          </div>
        
      
        </div>
        <div className="sm:pl-4 mt-4">
          <div>
            <h2
              className={
                theme === "light"
                  ? "sm:text-3xl text-5xl pb-4 font-russonOne font-medium no-underline align-middle tracking-wide normal-case leading-normal text-dark"
                  : "sm:text-3xl text-5xl pb-4 font-russonOne font-medium no-underline align-middle tracking-wide normal-case leading-normal text-white"
              }
            >
             
            </h2>
          </div>
          <img
            className="sm:h-[475px] h-[765px] w-full bg-no-repeat bg-center relative z-10 header rounded-xl"
            src={ring}
            alt="header-phone"
          ></img>
        </div>
      </div>
    </div>
  );
};

export default AerialGymnasticsAcademyInDelhi;
