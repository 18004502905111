import React,{useEffect} from 'react'
import "./AryanStyla.css"
import experience from "../../assets/imgAryan/data/experience.json";
import text from "../../assets/imgAryan/data/text.json";
import Aos from "aos";
import "aos/dist/aos.css"


function BestDanceAcademyInDelhiNcr() {

    useEffect(() => {
        Aos.init();
        
       }, [])

  return (
    <div  className="container ex mx-auto pt-16 " >
    
    {text.map((data) => {
      return (
        <>
          <div 
            key={data.id1}
            className="ex-items h-screen p-10 my-5 sm:grid-cols-1 grid grid-cols-2 text-center justify-items-center items-center "
            data-aos="zoom-in"
            data-aos-duration="1000"
          >
            <div className=" absolute ">
              <h2 className=' text-[2.5vw] m-2 px-5 '>{data.role1}</h2>
              <h4>
                <span className=' text-[2.5vw] mt-2 px-5 ' >
                  {data.startDate1} <br /> {data.endDate1}{" "} 
                </span>{" "}
                <span className='text-[2.5vw] mt-2  ' >{data.location1}</span>
              </h4>
              <h5 className='text-[2.5vw] mt-2 px-5 ' >{data.experiences1[0]}</h5>
              <h5  className='text-[2.5vw] mt-2  px-5'>{data.experiences1[1]}</h5>
            </div>
          </div>
        </>
      );
    })}   
    <h1  className=' font-bold text-[4vh]  '>EXPERIENCE</h1>
    {experience.map((data) => {
      return (
        <>
          <div
            key={data.id}
            className="ex-items p-10 text-center  my-5 sm:grid-cols-1 grid grid-cols-2  justify-items-center items-center "
            data-aos="zoom-in"
            data-aos-duration="1000"
          >
            <div className="left-img ">
              <img className=' w-36 ' src={`/aryanImg/${data.imageSrc}`} alt="" />
            </div>
            <div className="right">
              <h2 className=' text-[3vh] mt-1 '>{data.role}</h2>
              <h4>
                <span className=' mt-1 ' style={{ color: "yellowgreen" }}>
                  {data.startDate} {data.endDate}{" "}
                </span>{" "}
                <span className=' mt-1 ' style={{ color: "yellow" }}>{data.location}</span>
              </h4>
              <h5 className=' mt-1 ' style={{ color: "yellow" }}>{data.experiences[0]}</h5>
              <h5  className=' mt-1 'style={{ color: "yellow" }}>{data.experiences[1]}</h5>
            </div>
          </div>
        </>
      );
    })}
  </div>
  )
}

export default BestDanceAcademyInDelhiNcr