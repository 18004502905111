import "./AryanStyla.css"
import React , { useEffect, useRef, useContext } from 'react'
import { ThemeBgContext } from "../ContextWrapper/ThemeContext";
import hero from "../../assets/imgAryan/data/hero.json"
import Typed from "typed.js";
import BestDanceAcademyInDelhiNcr from "./BestDanceAcademyInDelhiNcr";
import Skills from "./GymansticsAcademyInVikasPuri";

// import Navbar from "./Navbar";

function AryanTongariya() {

    const { theme } = useContext(ThemeBgContext);

    const typedRef = useRef(null);
    useEffect(() => {
      const options = {
        strings: [
          "Welcome",
          " Aryan Kumar Tongariya",
          "Founder of A One Natraj Academy ",
         
        ],
        typeSpeed: 50,
        backSpeed: 50,
        loop: true,
      };
  
      const typed = new Typed(typedRef.current, options);
  
      return () => {
        typed.destroy();
      };    
    }, []);
  

    const typedRef1 = useRef(null);
    useEffect(() => {
      const options = {
        strings: [
          " Suman Tongariya",
          "Manager of A One Natraj Academy ",
         
        ],
        typeSpeed: 50,
        backSpeed: 50,
        loop: true,
      };
  
      const typed = new Typed(typedRef1.current, options);
  
      return () => {
        typed.destroy();
      };    
    }, []);

    return (
      <>
      <div className="bg-container">
{/* <Navbar/> */}
    
       <div  className=" mx-auto text-center mt-8 pt-16">
      

      <div className="sm:grid-cols-1 grid grid-cols-2  justify-items-center items-center">
        <div className="sm:w-full sm:pl-4 w-4/5 pl-24">
       
              <div className="textstructure w-auto mr-2 mt-1 px-50   ">
              
              <div className="left font-semibold text-[2vh] align-middle tracking-wide text-white" data-aos="fade-up-right" data-aos-duration="1000">
          <h1 ref={typedRef}>Aryan</h1>
        </div>  
        
</div>  
        </div>
        <div className="sm:pl-4 mt-4">
          <div>
            <h2
              className={
                theme === "light"
                  ? "sm:text-3xl text-5xl pb-4 font-russonOne font-medium no-underline align-middle tracking-wide normal-case leading-normal text-dark"
                  : "sm:text-3xl text-5xl pb-4 font-russonOne font-medium no-underline align-middle tracking-wide normal-case leading-normal text-white"
              }
            >
             
            </h2>
          </div>
          <div>
          <div className="right sm:pl-4 mt-4">
          <p className=" text-[8vw]">Aryan Kumar Tongariya</p>
          <div className="img sm:h-[475px] h-[765px] w-full bg-no-repeat bg-center relative z-10 header rounded-xl" data-aos="fade-up-left" data-aos-duration="1000">
            <img  src={`/aryanImg/${hero.imgSrc}`} alt="hero" />
          </div>
        </div>
            </div>
        </div>
        
      </div>

      
      <div className="sm:grid-cols-1 grid grid-cols-2  justify-items-center items-center">
        <div className="sm:w-full sm:pl-4 w-4/5 pl-24">
       
              <div className="textstructure w-auto mr-2 mt-1 px-50   ">
     
              <div className="left font-semibold text-[2vh] align-middle tracking-wide text-white" data-aos="fade-up-right" data-aos-duration="1000">
          <h1 ref={typedRef1}>Suman</h1>

        
        </div>
        
</div>
        </div>
        <div className="sm:pl-4 mt-4">
          <div>
            <h2
              className={
                theme === "light"
                  ? "sm:text-3xl text-5xl pb-4 font-russonOne font-medium no-underline align-middle tracking-wide normal-case leading-normal text-dark"
                  : "sm:text-3xl text-5xl pb-4 font-russonOne font-medium no-underline align-middle tracking-wide normal-case leading-normal text-white"
              }
            >
             
            </h2>
          </div>
          <div>
          <div className="right sm:pl-4 mt-4">
          <p className=" text-[8vw]">Suman Tongariya</p>
          <div className="img sm:h-[475px] h-[765px] w-full bg-no-repeat bg-center relative z-10 header rounded-xl" data-aos="fade-up-left" data-aos-duration="1000">
            
            <img  src={`/aryanImg/${hero.imgSuman}`} alt="hero" />
          </div>
        </div>
            </div>
        </div>
              
      </div>

    </div>
    <BestDanceAcademyInDelhiNcr/>
    <Skills/>
  
    </div>
      </>
    );
}

export default AryanTongariya