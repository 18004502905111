import { motion } from 'framer-motion';
import React,{useContext} from 'react'
import { ThemeBgContext } from "../ContextWrapper/ThemeContext";
import { aboutCardData } from "../../DataForPage/dummyData";
// import { aboutCard } from "../../DataForPage/dummyData";
// import AboutWrapper from '../WrapperContainer/AboutWrapper';

import {
  Card,
  CardBody,
  CardFooter, 
  Typography,
} from "@material-tailwind/react";
// import AboutWrapper from '../WrapperContainer/AboutWrapper';
import WrapperContainer from '../WrapperContainer/WrapperContainer';
import Footer from '../Footer/AerialClassesInDelhi';


function BestDanceAcademyForKidsInVikasPuriJanakPuri() {
  const { theme } = useContext(ThemeBgContext);
  return (
    <div>
       <WrapperContainer>
    <div className="text-center mt-28">
      <h2
        className={
          theme === "light"
            ? "sm:text-5xl text-dark text-6xl font-bold pt-20 no-underline font-inter align-middle tracking-wide normal-case leading-none cursor-pointer"
            : "sm:text-5xl text-white text-6xl font-bold pt-20 no-underline font-inter align-middle tracking-wide normal-case leading-none cursor-pointer"
        }
      >
     About Us
      </h2>
      <p
        className={
          theme === "light"
            ? "sm:text-xl text-dark w-full px-20   text-2xl font-normal pt-10 no-underline font-inter align-middle tracking-wide normal-case leading-none cursor-pointer"
            : "sm:text-xl text-white w-full px-20  text-2xl font-normal pt-10 no-underline font-inter align-middle tracking-wide normal-case leading-none cursor-pointer"
        } 
      > 
      We opened our door as Aone natraj academy, in 2010 established by Mr. Aryan Kumar. who introduced all dance style under one roof. we cater for all levels from beginners to professionals. Through our most unique astounding pattern
 of teaching dance, kids love to learn different dance, it helps them to become a versatile dancer. We provide a professional training with good supervision to every individual, so that kids explore themselves. <br /> <br /> Our
  honest feedback, positive encouragement and the most important parents cooperation gives students very good enthusiasm which help them to show massive improvement. </p>

    </div>
   
    <div className="sm:grid-cols-1 grid grid-cols-3 items-center gap-8 content-between w-full pt-32"> 
      {aboutCardData.map((item) => {
        return (
          <motion.div
            className="sm:pl-0 mx-auto pl-20"
            key={item.id}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 1.5 }}
            variants={{
              visible: { opacity: 1, scale: 1 },
              hidden: { opacity: 0, scale: 0 },
            }}
          >
            <Card
              className={
                item.id === "1"
                  ? "w-80 bg-dark border border-white"
                  : "w-80 bg-white"
              }
            >
              <CardBody className="text-center">
                <Typography
                  variant="h5"
                  className={
                    item.id === "1"
                      ? "mb-2 fonm-inter leading-normal no-underline align-middle tracking-wide normal-case text-white"
                      : "mb-2 fonm-inter leading-normal no-underline align-middle tracking-wide normal-case text-dark"
                  }
                >
                  {item.title}
                </Typography>
                <Typography
                  className={
                    item.id === "1"
                      ? "mb-2 fonm-inter font-light leading-normal no-underline align-middle tracking-wide normal-case h-32 text-white"
                      : "mb-2 fonm-inter font-light  leading-normal no-underline align-middle tracking-wide normal-case h-32 text-dark"
                  }
                >
                  <img src={item.img} alt="" />
                </Typography>
              </CardBody>
              <CardFooter
                divider
                className="flex mt-6 flex-col items-center content-center justify-center py-3"
              >
                <Typography
                  variant="small"
                  className={
                    item.id === "1"
                      ? "text-center mt-2 px-5 font-inter font-light leading-normal no-underline align-middle hover:animate-pulse tracking-wide cursor-pointer text-white"
                      : "text-center mt-2 px-5 font-inter font-light leading-normal no-underline align-middle hover:animate-pulse tracking-wide cursor-pointer text-dark"
                  }
                >
                 {item.text}
                </Typography>
              </CardFooter>
            </Card>
          </motion.div>
        );
      })}
    </div>
    </WrapperContainer>
    <div>
      <Footer></Footer>
    </div>
  </div>
  
  )
}

export default BestDanceAcademyForKidsInVikasPuriJanakPuri