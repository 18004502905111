import silk from "../assets/images/silk.png";
import hoop from "../assets/images/ring.png";
import pole from "../assets/images/pole.png";
// -------------------------
import calisthenics from "../assets/images/calisthenics.png";
import german from "../assets/images/gymnastics-academy-in-vikas-puri gymnastics-academy-in-delhi best-gymnastics-academy-in-west-delhi best-gymnastics-academy-in-vikaspuri gymnastics-academy-for-kids gymnastics.png";
import cyr from "../assets/images/cyr.png";
// -------------------------------------------------Activitise----------------
import classicalW from "../assets/images/best-dance-academy-in-delhi best-dance-academy-for-kids-in-west-delhi classical-dance-academy-in-dehi kathak-dance-academy-in-janakpuri-vikaspuri kathak-classes-in-vikaspuri-w.png";
import gymnasticsW from "../assets/images/gymnasticsB.png";
import westerW from "../assets/images/best-dance-academy-in-delhi best-dance-academy-for-kids-in-west-delhi western-dance-academy-in-dehi dance-dance-academy-in-janakpuri-vikaspuri dance-classes-in-vikaspuri.png";
import classicalB from "../assets/images/best-dance-academy-in-delhi best-dance-academy-for-kids-in-west-delhi classical-dance-academy-in-dehi kathak-dance-academy-in-janakpuri-vikaspuri kathak-classes-in-vikaspuri.png";
import gymnasticsB from "../assets/images/gymnasticsW.png";
import westerB from "../assets/images/best-dance-academy-in-delhi best-dance-academy-for-kids-in-west-delhi western-dance-academy-in-dehi dance-dance-academy-in-janakpuri-vikaspuri dance-classes-in-vikaspuri b.png";
import acting from "../assets/images/acting2.png";
import art from "../assets/images/art&craftFinle.png";
// --------------------------------------------------fitness-------------------------
import box1 from "../assets/images/cardio.gif";
import box2 from "../assets/images/mat.gif";
import box3 from "../assets/images/zumba.gif";
import box4 from "../assets/images/box.gif";
// --------------------------------------------------About Us----------------
import Y2011 from "../assets/About/a-one-natraj-academy (1).JPG";
import Y2012 from "../assets/About/a-one-natraj-academy (2).JPG";
import Y2013 from "../assets/About/a-one-natraj-academy (3).JPG";
import Y2014 from "../assets/About/a-one-natraj-academy (4).jpg";
import Y2015 from "../assets/About/a-one-natraj-academy (5).JPG";
import Y2016 from "../assets/About/a-one-natraj-academy (6).JPG";
import Y2017 from "../assets/About/a-one-natraj-academy (7).jpg";
import Y2018 from "../assets/About/a-one-natraj-academy (8).jpg";
import Y2019 from "../assets/About/a-one-natraj-academy (9).JPG";

// export const routers = [
//   {
//     id:"about",
//     name: "About",
//     link: "/about",
//   }
// ];


export const navbarData = [
  {
    id: "1",
    name: "Gymnastics",
    link: "gymnastics",
  },
  {
    id: "2",
    name: "WeddingChoreo",
    link: "wedding",
  },
  {
    id: "3",
    name: "Activites",
    link: "activites",
  },
  {
    id: "4",
    name: "Fitness",
    link: "fitness",
  },
  {
    id: "5",
    name: "Courses",
    link: "courses",
  },
  {
    id: "6",
    name: "Contact Us",
    link: "contact",
  },
];
export const FooterDanceData = [
  {
    id: "1",
    text: "Hip Hop",
  },
  {
    id: "2",
    text: "Jazz",
  },
  {
    id: "3",
    text: "Contamporary",
  },
  {
    id: "4",
    text: "Bollywood",
  },
  {
    id: "5",
    text: "Salsa",
  },
  {
    id: "6",
    text: "Classical",
  },
];
export const FooterGymnasticsData = [
  {
    id: "1",
    text: "Aerial Hoop",
    path: "Resources",
  },
  {
    id: "2",
    text: "Aerial Silk",
  },
  {
    id: "3",
    text: "Pole Gymnastics",
  },
  {
    id: "4",
    text: "German Wheel",
  },
  {
    id: "4",
    text: "Calisthenics",
  },
  {
    id: "4",
    text: "Cyr Wheel",
  },
];
export const FooterCoursData = [
  {

    text: "Teaching",
  },
  {

    text: "Hip Hop Dance",
  },
  {

    text: "Jazz",
  },
  {
    text: "Contamporary",
  },
  {
    text: "Aerial Hoop",
  },
  {
    text: "Aerial Silk",
  },

];
export const FooterNoreData = [
  {
    id: "1",
    text: "Art and Craft",
  },
  {
    id: "2",
    text: "Painting",
  },
  {
    id: "3",
    text: "Sketching",
  },
  {
    id: "4",
    text: "Acting",
  },
  {
    id: "5",
    text: "Modeling",
  },
];
export const sliderData1 = [
  {
    id: "1",
    title: "Calisthenics",
    text: "Calisthenics is a form of strength training that uses your own body weight as resistance. It's a dynamic and versatile exercise method that can be done almost anywhere, with minimal equipment. Here's a breakdown of what calisthenics is all about:",
    type: "Calisthenics",
    img: calisthenics,
  },
  {
    id: "2",
    title: "GermanWheel",
    text: "The German wheel, also known as the Rhönrad, gymnastics wheel, gym wheel, or Ayro wheel, is a unique and challenging gymnastics apparatus that offers a thrilling blend of acrobatics, dance, and artistic expression",
    type: "GermanWheel",
    img: german,
  },
  {
    id: "3",
    title: "CyrWheel",
    text: "The Cyr wheel, also known as the roue Cyr or simply the monowheel, is a captivating gymnastics apparatus that takes artistry and athleticism to a whole new level. Imagine yourself gliding and spinning gracefully within a large metal ring – that's the essence of the Cyr wheel!",
    type: "CyrWheel",
    img: cyr,
  },
];
export const sliderData = [
  {
    id: "1",
    title: "Aerial Hoop",
    text: "Aerial hoop classes offer a fun and challenging workout that builds strength, flexibility, and artistry.  The class structure will vary depending on the level (beginner, intermediate, advanced), but here's a general breakdown of what you can expect:",
    type: "Hoop",
    img: hoop,
  },
  {
    id: "2",
    title: "Aerial Silk",
    text: "Aerial Silks  is a vertical apparatus consisting of two pieces of fabric suspended from the ceiling. Silks class will build your upper body strength, hand grip, flexibility and coordination. You will learn all the basics and techniques you need in order to progress.",
    type: "Silk",
    img: silk,
  },
  {
    id: "3",
    title: "Pole Gymnastics",
    text: "Pole Fitness combines dance, acrobatics, and gymnastics with the focus of the sport centered around a vertical pole. Pole has gained popularity as a form of fitness, and is practiced today in gyms and studios. There are hundreds of professional and amateur competitions held around the world annually.",
    type: "Pole",
    img: pole,
  },
];


export const activities = [
  {
    id: "0",
    label: "Western",
    value: "western",
    desc: `This refers to a variety of social dance styles typically danced to country-western music,`,
    img: westerW,
    img2: westerB,
    seeMore:"These dances often have roots in European folk dances brought by immigrants to the Americas and have evolved over time. Here are some common examples.",
    linkText: "See more",
  },
  {
    id: "1",
    label: "Gymnastics",
    value: "livingroom",
    desc: "Aerial gymnastics, unlike the term might imply, isn't directly related to traditional floor gymnastics. Instead, it's a unique and thrilling discipline that combines acrobatics and dance using aerial equipment suspended from the ceiling",
    img: gymnasticsW,
    img2: gymnasticsB,
    seeMore:`Aerial gymnastics can be enjoyed by people of various ages and fitness levels.  Beginner classes are available, and instructors can help you progress at your own pace, focusing on safety and proper technique before attempting more advanced skills.`,
    linkText: "See more",
  },

  {
    id: "2",
    label: "Classical",
    value: "kitchen",
    desc: `Multiples Math in Classical Dance (if applicable to your learning level)
    Learn a basic classical dance sequence (teacher's guidance recommended for proper technique).
    Many classical dance steps involve multiples`,
    img: classicalW,
    img2: classicalB,
    seeMore:``,
    linkText: "See more",
  },

  {
    id: "3",
    label: "Art&Craft",
    value: "office",
    desc: ` This art and craft course is your invitation to explore a world of artistic expression! Whether you're a seasoned artist or a complete beginner, `,
    img: art,
    img2: art,
    seeMore:`this program offers a fun and engaging environment to learn new techniques, experiment with different materials, and discover your artistic voice.`,
    linkText: "See more",
  },

  {
    id: "4",
    label: "Acting",
    value: "kidsroom",
    desc: `This comprehensive acting and modeling course equips you with the skills and confidence to shine in the performing arts and visual media,`,
    img: acting,
    img2: acting,
    seeMore:"Whether you dream of gracing the stage or captivating the camera, this program provides a foundation for both disciplines, helping you discover your unique potential.",
    linkText: "See more",
  },
];

export const cardData = [
  {
    id: "0",
    title: "Teacher Traning",
    text: "This comprehensive course equips you with the skills and knowledge to become a confident and inspiring dance teacher.  Whether you dream of leading classes at a studio, working in a school setting, or sharing your passion for dance in the community, this program provides the foundation for your teaching journey.",
  },
  {
    id: "1",
    title: "Hip Hop Dance",
    text: "This course is your invitation to dive into the world of hip hop dance!  Get ready to learn foundational moves, build confidence, and express yourself through movement.",
  },
  {
    id: "2",
    title: "Jazz",
    text: "This course is your chance to step into the world of jazz dance, a dynamic and expressive dance style that fuses energy, musicality, and personal flair.  Get ready to learn the foundational techniques, explore different styles, and discover your own unique voice as a jazz dancer.",
  },
  {
    id: "3",
    title: "Contemporary",
    text: "This course is your invitation to delve into the world of contemporary dance, a dynamic and ever-evolving art form.  Get ready to explore the depths of your physicality, unleash your creativity, and discover a powerful mode of self-expression.",
  },
  {
    id: "1",
    title: "Aerial Act",
    text: "This course is your gateway to the exhilarating world of AERIAL HOOP! AERIAL SILK! Get ready to defy gravity, build strength and flexibility, and discover the joy of aerial movement in a safe and supportive environment.",
  },
  {
    id: "5",
    title: "Art and Craft",
    text: "This course is your invitation to explore the wonderful world of art and craft! Whether you're a seasoned artist or a complete beginner, this program offers a fun and engaging environment to learn new techniques, experiment with different materials, and discover your artistic voice",
  },
];

export const weddingChoreography1 = [
  // {
  //   id: "1",
  //   name1: "A choreographed dance adds a special touch to your wedding reception, leaving a lasting impression on your guests",
  //   boldText1: "Create a Memorable Moment",
   
    
  // },  
  {
    id: "1",
    name: " Breakthrough any pre-wedding jitters and feel confident and graceful on the dance floor.",
    boldText: "Boost Confidence",
    linkText: "See more",
  },
  {
    id: "2",
    name: "Your chosen song and custom choreography can tell a unique story about your relationship.",
    boldText: "Express Your Love Story",
    
  },
  {
    id: "3",
    name: "Your chosen song and custom choreography can tell a unique story about your relationship.",
    boldText: "Fun & Bonding Experience",
   seeLess1:"See Less"
    
  }
];

export const weddingChoreography = [
  // {
  //   id: "1",
  //   name: "Meet with are taem to discuss your musical preferences, dance experience, and vision for the dance. They'll create choreography tailored to you as a couple.",
  //   boldText: "Collaborative Creation",
    
  // },
  {
    id: "2",
    name: "Your choreographer will create a unique routine tailored to your skills and comfort level. It can be a romantic waltz, a playful swing number, or anything that reflects your personalities.",
    boldText: "Custom Choreography",
    linkText: "See more",
  },
  {
    id: "3",
    name: "Learn the routine at your own pace through private or group lessons. Your choreographer will break down the moves, provide personalized feedback, and ensure you feel confident on the dance floor",
    boldText: "Step-by-Step Instruction:",
    seeLess:"See Less"
   
  }
];
export const weddingChoreography2 = [
  // {
  //   id: "1",
  //   name: "The classic first dance, a romantic spotlight on your love.",
  //   boldText: "Couples Dance",
    
  // },
  {
    id: "2",
    name: " Get your family and friends involved with a fun and energetic group performance.",
    boldText: "Group Dances",
    linkText: "See more",
  },
  {
    id: "3",
    name: "A heartwarming dance with your parents to create lasting memories.",
    boldText: "Parent Dances",
    
  },
  {
    id: "3",
    name: " Surprise your guests with an unexpected and unforgettable dance performance!",
    boldText: "Flashmobs",
    seeLess2: "See Less"
  }
];

export const fitness = [
  {
    id: "1",
    name: "Physical fitness is a state of health & well being.",
    img: box1,
  },
  {
    id: "2",
    name: "Mat workout helps in tonning up muscles improves posture.",
    img: box2,
  },
  {
    id: "3",
    name: "make workout more enjoyable, interesting and more effective we have set a planner as follows zumba.",
    img: box3,
  },
  {
    id: "4",
    name: "This is a form of aerobics exercise that involves steping on and off a small platform (steper).",
    img: box4,
  },
];

export const aboutCardData = [
  {
    id: "0",
    title: "Annual Function 2011",
    text: "We had our first annual function in the year 2011,held at kamani auditorium, MR.and MRS. Sehgal founder and principal of Adarsh public school gave there presence as chief guest, All performances were mesmerizing , it was wonderfulexperience for kids as well as for parents.",
    img: Y2011,
  },
  {
    id: "1",
    title: "Annual Function 2012",
    text: "we had our sec annual function was held at technia auditorium in Rohini , our honorable chief guest were Dr.Rama ,she appreciated all the performance,",
    img: Y2012,
  },
  {
    id: "2",
    title: "Annual Function 2013",
    text: "We always try to showcase something some uniqueness in our function, we introduce UV light dance, it was treat for an eye.Honorable Chief guest was Mr. Asia , Mayank.our annual function was shown on news Channel (channel one) as well.",
    img: Y2013,
  },
  {
    id: "1",
    title: "Annual Function 2014",
    text: "We conducted our annual function 2014 at technia auditorium in Rohini. Once again. Kids banged on with there performances. All performances were flawless.",
    img: Y2014,
  },
  {
    id: "2",
    title: "Annual Function 2015",
    text: "Annual function 2015 was one of the best annual function in the past 4years. We introduced. Silk malkham, aerial hoop. All performances were astounding, Mr. Rajeev kapur managing director of steelbird and Gurchanar singh fromGsk production gave there presence and highly appreciated students and teachers hard work.",
    img: Y2015,
  },
  {
    id: "1",
    title: "Annual Function 2016",
    text: "We conducted our 6th at satya sai auditorium, lodhi road, kids performed dance styles. All performances were flawless.",
    img: Y2016,
  },
  {
    id: "2",
    title: "Annual Function 2017",
    text: "we organised 2017 annual function at Sarvapalli radha krishan auditorium. we introduced german wheel & cyr wheel.Our little kiddos with age group 4.5 also participated. parents were thrilled watching toddlers tapping therefeet on stage. Mr. rajeev kapur , managing director of steelbirds & Mrs sweety kapur were our honorable chief guest , they not only appreciated but thoroughly enjoyed our show.",
    img: Y2017,
  },
  {
    id: "1",
    title: "Annual Function 2018",
    text: "We organised annual function 2018 at Air force auditorium,Mr. Rishi raj (film director) and Mrs. Meena Malik gave there presence As our honorable chief guest, they were thrilled watching such mesmerising performances. All performances were breathtaking, it was not less than an iifa award. all credit goes to aryan sir, and team. ",
    img: Y2018,
  },
  {
    id: "2",
    title: "Annual Function 2019",
    text: "Customize your own Bedroom, LivingRoom, Kitchen, Office and much more. Anytime, in a matter of seconds.",
    img: Y2019,
  },

];

export const weddingCardData = [
  {
    id: "0",
    title: "Annual Function 2011",
    text: "We had our first annual function in the year 2011,held at kamani auditorium, MR.and MRS. Sehgal founder and principal of Adarsh public school gave there presence as chief guest, All performances were mesmerizing , it was wonderfulexperience for kids as well as for parents.",
  },
  {
    id: "1",
    title: "Annual Function 2012",
    text: "We had our first annual function in the year 2011,held at kamani auditorium, MR.and MRS. Sehgal founder and principal of Adarsh public school gave there presence as chief guest, All performances were mesmerizing , it was wonderfulexperience for kids as well as for parents.",
  },
  {
    id: "2",
    title: "Annual Function 2012",
    text: "We had our first annual function in the year 2011,held at kamani auditorium, MR.and MRS. Sehgal founder and principal of Adarsh public school gave there presence as chief guest, All performances were mesmerizing , it was wonderfulexperience for kids as well as for parents.",
  }, {
    id: "1",
    title: "Annual Function 2012",
    text: "We had our first annual function in the year 2011,held at kamani auditorium, MR.and MRS. Sehgal founder and principal of Adarsh public school gave there presence as chief guest, All performances were mesmerizing , it was wonderfulexperience for kids as well as for parents.",
  }, {
    id: "2",
    title: "Annual Function 2012",
    text: "We had our first annual function in the year 2011,held at kamani auditorium, MR.and MRS. Sehgal founder and principal of Adarsh public school gave there presence as chief guest, All performances were mesmerizing , it was wonderfulexperience for kids as well as for parents.",
  }, {
    id: "1",
    title: "Annual Function 2012",
    text: "We had our first annual function in the year 2011,held at kamani auditorium, MR.and MRS. Sehgal founder and principal of Adarsh public school gave there presence as chief guest, All performances were mesmerizing , it was wonderfulexperience for kids as well as for parents.",
  },


];
