import React, {useContext} from 'react'
import { ThemeBgContext } from "../ContextWrapper/ThemeContext";

function AboutWrapper({navHead, children }) {
  const { theme } = useContext(ThemeBgContext);
  return (
    <div>
    <div
    className={
      !navHead
        ? `container mx-auto px-4 mt-20 ${
            theme === "light" ? " bg-wrapperBg" : "bg-wrapperDark"
          } h-full w-full bg-cover bg-no-repeat bg-center rounded-xl -translate-y-36 drop-shadow-2xl`
        : "container mx-auto px-4"
    }
  >
    {children}
  </div>
  
  </div>
  )
}

export default AboutWrapper