import React, { useContext, useState } from "react";
import { fitness } from "../../DataForPage/dummyData";
import { weddingChoreography } from "../../DataForPage/dummyData";
import { weddingChoreography1 } from "../../DataForPage/dummyData";
import { weddingChoreography2 } from "../../DataForPage/dummyData";
import { motion } from "framer-motion";
import { ThemeBgContext } from "../ContextWrapper/ThemeContext";
// import { useNavigate } from "react-router-dom";


const DanceClassesForLadiesInVikasPuri = ({ img,  reverse}) => {
  const { theme } = useContext(ThemeBgContext);
  

const [showMore, setShowMore] = useState(false);
const handleClick = () => {
  setShowMore(!showMore); // Toggle the visibility state
};

const [showMore1, setShowMore1] = useState(false);
const handleClick1 = () => {
  setShowMore1(!showMore1); // Toggle the visibility state
};

const [showMore2, setShowMor2] = useState(false);
const handleClick2 = () => {
  setShowMor2(!showMore2); // Toggle the visibility state
};
  return (
    <div id="wedding">
        
      {reverse ? (
        <div className="sm:grid-cols-1 grid grid-cols-2 justify-items-center items-center">
          

           
         <div className="sm:w-full sm:pl-4 pb-4">
          
         <div className="flex flex-col">
            <h1 
              className={
                theme === "light"
                  ? "text-[1.5vw] sm:text-xl pt-10 pb-4 font-bold font-inter no-underline align-middle tracking-wide normal-case leading-none text-dark"
                  : "text-[1.5vw] sm:text-xl pt-10 pb-4 font-bold font-inter no-underline align-middle tracking-wide normal-case leading-none text-white"
              }
            >
              Why Choose Wedding Choreography?
            </h1>
            <div className="mr-4 sm:text-xl text-[1vw] font-bold text-yellow-800">Create a Memorable Moment</div>
            <p  
                      className={
                        theme === "light"
                          ? "sm:text-xl text-1xl font-normal font-inter no-underline align-middle tracking-wide normal-case leading-none text-dark w-full "
                          : "sm:text-xl text-1xl font-normal font-inter no-underline align-middle tracking-wide normal-case leading-none text-white w-full "
                      }
                    >
                     A choreographed dance adds a special touch to your wedding reception, leaving a lasting impression on your guests

                    </p>
              {weddingChoreography1.map((item) => {
                return (
                  <div className=" text-[1vw] font-bold py-2" key={item.id}>
                    
                    <div className="mr-4  sm:text-xl text-yellow-800">
                    
                    {item.boldText.slice(0, showMore1 ? item.boldText.length : '')} 
                     {/* {item.boldText}:  */}
                    </div>
                 
                    <p  
                      className={
                        theme === "light"
                          ? "sm:text-xl text-1xl font-normal font-inter no-underline align-middle tracking-wide normal-case leading-none text-dark w-full "
                          : "sm:text-xl text-1xl font-normal font-inter no-underline align-middle tracking-wide normal-case leading-none text-white w-full "
                      }
                    >
                      {item.name.slice(0, showMore1 ? item.name.length : '')} 
                      {/* {item.name} */}
                    </p>
                    <span className=" text-cyan-500 font-inter leading-normal text-[2vh] no-underline align-middle tracking-wide normal-case relative cursor-pointer right-3 hover:animate-puls">
      <button onClick={handleClick1}>
        {showMore1 ?  <div className=" p-1">{item.seeLess1}</div>: 
        
        <div className=" p-1">
          {item.linkText}
        </div>
        }
      </button>
                </span>
                  </div>
                );
              })}
            </div>
          {/* ----------------------------------------------------------------- */}
            <div className="flex flex-col">
            <h1 
              className={
                theme === "light"
                  ? "text-[1.5vw] sm:text-xl  pt-10 pb-4 font-bold font-inter no-underline align-middle tracking-wide normal-case leading-none text-dark"
                  : "text-[1.5vw]  sm:text-xl pt-10 pb-4 font-bold font-inter no-underline align-middle tracking-wide normal-case leading-none text-white"
              }
            >
              How it Works
            </h1>
            <div className="mr-4 sm:text-xl text-[1vw] font-bold text-yellow-800">Collaborative Creationt</div>
            <p  
                      className={
                        theme === "light"
                          ? "sm:text-xl text-1xl font-normal font-inter no-underline align-middle tracking-wide normal-case leading-none text-dark w-full "
                          : "sm:text-xl text-1xl font-normal font-inter no-underline align-middle tracking-wide normal-case leading-none text-white w-full "
                      }
                    >
                   Meet with are taem to discuss your musical preferences, dance experience, and vision for the dance. They'll create choreography tailored to you as a couple.

                    </p>
              {weddingChoreography.map((item) => {
                return (
                  <div className=" text-[1vw] font-bold py-2" key={item.id}>
                    <div className="mr-4 sm:text-xl text-yellow-800">
                    {item.boldText.slice(0, showMore ? item.boldText.length : '')} 
                    </div>
                    <p  
                      className={
                        theme === "light"
                          ? "sm:text-xl text-1xl font-normal font-inter no-underline align-middle tracking-wide normal-case leading-none text-dark w-full "
                          : "sm:text-xl text-1xl font-normal font-inter no-underline align-middle tracking-wide normal-case leading-none text-white w-full "
                      }
                    >
                      {item.name.slice(0, showMore ? item.name.length : '')} 
                    </p>
                    <span className=" text-cyan-500 font-inter text-[2vh] leading-normal no-underline align-middle tracking-wide normal-case relative cursor-pointer right-3 hover:animate-puls">
      <button onClick={handleClick}>
        {showMore ?  <div className=" p-1">{item.seeLess}</div>: 
        
        <div className=" p-1">
          {item.linkText}
        </div>
        }
      </button>
                </span>
                  </div>
                );
              })}
            </div>

          {/* ----------------------------------------------------------------- */}
 <div className=" flex flex-col">
            <h1 
              className={
                theme === "light"
                  ? "text-[1.5vw] sm:text-xl pt-10 pb-4 font-bold font-inter no-underline align-middle tracking-wide normal-case leading-none text-dark"
                  : "text-[1.5vw] sm:text-xl pt-10 pb-4 font-bold font-inter no-underline align-middle tracking-wide normal-case leading-none text-white"
              }
            >
              We Offer Choreography for
            </h1>
            <div className="mr-4 sm:text-xl text-[1vw] font-bold text-yellow-800">Couples Dance</div>
            <p  
                      className={
                        theme === "light"
                          ? "sm:text-xl text-1xl font-normal font-inter no-underline align-middle tracking-wide normal-case leading-none text-dark w-full "
                          : "sm:text-xl text-1xl font-normal font-inter no-underline align-middle tracking-wide normal-case leading-none text-white w-full "
                      }
                    >
                 The classic first dance, a romantic spotlight on your love.
                    </p>
              {weddingChoreography2.map((item) => {
                return (
                  <div className=" text-[1vw] font-bold py-2" key={item.id}>
                    <div className="mr-4 sm:text-xl text-yellow-800">
                    {item.boldText.slice(0, showMore2 ? item.boldText.length : '')} 
                    </div>
                    <p  
                      className={
                        theme === "light"
                          ? "sm:text-xl text-1xl font-normal font-inter no-underline align-middle tracking-wide normal-case leading-none text-dark w-full "
                          : "sm:text-xl text-1xl font-normal font-inter no-underline align-middle tracking-wide normal-case leading-none text-white w-full "
                      }
                    >
                       {item.name.slice(0, showMore2 ? item.name.length : '')} 
                    </p>
                    <span className="  text-cyan-500 font-inter text-[2vh] leading-normal no-underline align-middle tracking-wide normal-case relative cursor-pointer right-3 hover:animate-puls">
      <button onClick={handleClick2}>
        {showMore2 ?  <div className=" p-1">{item.seeLess2}</div>: 
        
        <div className=" p-1">
          {item.linkText}
        </div>
        }
      </button>
                </span>
                  </div>
                );
              })}
            </div>

          </div>
        
          <motion.div
            className=""
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 1.5 }}
            variants={{
              visible: { opacity: 1, scale: 1 },
              hidden: { opacity: 0, scale: 0 },
            }}
          >
            <img
              className="sm:h-[475px] h-[775px] pt-10 hover:rotate-6 duration-1000 cursor-pointer"
              src={img}
              alt="phone1"
            ></img>
          </motion.div>
        </div>
      ) : (
        <div className=" text-center" id="fitness">
             <h2
              className={
                theme === "light"
                  ? "sm:text-4xl text-6xl pt-10 pb-4 font-bold font-inter no-underline align-middle tracking-wide normal-case leading-none text-dark"
                  : "sm:text-4xl text-6xl pt-10 pb-4 font-bold font-inter no-underline align-middle tracking-wide normal-case leading-none text-white"
              }
            >
              Fitness Classes
            </h2>
        <div className="sm:grid-cols-1 grid grid-cols-2 justify-items-center items-center">
     
        <div>
            <img
              className="sm:h-[475px] h-[775px] pt-1 hover:rotate-[-6deg] duration-1000 cursor-pointer"
              src={img}
              alt="fitness"
            ></img>
          </div>
          <div className="sm:w-full sm:pl-4 pb-4">
            <div className="flex flex-col">
              {fitness.map((item) => {
                return (
                  <div className="flex items-center py-2" key={item.id}>
                    <div className="mr-4">
                      <img
                        className="w-16 h-16"
                        src={item.img}
                        alt="fitness"
                      ></img>
                    </div>
                    <p
                      className={
                        theme === "light"
                          ? "sm:text-xl text-2xl font-normal font-inter no-underline align-middle tracking-wide normal-case leading-none text-dark w-full "
                          : "sm:text-xl text-2xl font-normal font-inter no-underline align-middle tracking-wide normal-case leading-none text-white w-full "
                      }
                    >
                      {item.name}
                    </p>
                  </div>
                );
              })}
            </div>
          
          </div>
       
        </div>
        </div>
      )}
    </div>
  );
};

export default DanceClassesForLadiesInVikasPuri;
