import React, { useContext, useRef } from "react";
import Header from "../Header/AerialGymnasticsAcademyInDelhi";
import Navbar from "../Navbar/KathakDanceClassesInVikasPuri";
// import WrapperContainer from "../WrapperContainer/WrapperContainer";
import FitnessAndAcademy from "../MidSection/FitnessClassesForLadiesInVikasPuri";
import Activites from "../MidSection/HipHopDanceCoursesInDelhiNcr";
// import FurnitureFlow from "../MidSection/FurnitureFlow";

import Cards from "../MidSection/HipHopDanceClassesInWestDelhi";
import Footer from "../Footer/AerialClassesInDelhi";
// import PopUpButton from "../PopUpButton/PopUpButton";
import { ThemeBgContext } from "../ContextWrapper/ThemeContext";
import Nav from "../Navbar/AerialHoopClassInDelhi";
import TipesOfGymnas from "../MidSection/GymnasticsAcademyInVikasPuri";
import Contact from "../MidSection/Top10WeddingChoreographerInDelhi";
// import Library from "../libraryModal/LibraryModal"
import AboutWrapper from "../WrapperContainer/AboutWrapper";
  
const Home = () => {
  const sectionTop = useRef();
  const { theme } = useContext(ThemeBgContext); 
  // const handleScroll = () => { 
  //   sectionTop.current?.scrollIntoView({ behavior: "smooth" });
  // };
  return (
    <div className="relative">
      <Nav></Nav>
      <div>
        {/* <PopUpButton handleScroll={handleScroll}></PopUpButton> */}
      </div>
      <div className={theme === "light" ? "bg-white" : " bg-black"}>
        <AboutWrapper navHead>
          <Navbar></Navbar>
          <Header topRef={sectionTop}></Header>
        </AboutWrapper>
        
      <div className={theme === "light" ? "bg-white" : " bg-black"}>
        <AboutWrapper>
          <TipesOfGymnas></TipesOfGymnas>
          <FitnessAndAcademy></FitnessAndAcademy>
          <Activites></Activites>    
          <Cards></Cards>
          <Contact></Contact>
        </AboutWrapper>
     
      </div>
      </div>
      <div>
          <Footer></Footer>
        </div>
     
    </div>
  );
};
export default Home;
