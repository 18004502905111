import React from 'react';
import './ErrorPage.css'
import errorImg from "./error1.png"
const ErrorPage = () => {

    
  return (
    <div className=" w-full h-screen bg-blue-gray-500 error-page">
      <h1 className=" text-white error-title">Oops, something went wrong!</h1>
      <p className=" text-white  error-message">We're sorry, but we encountered an error.</p>

      {/* {error.message && <p className="error-detail">{error.message}</p>}

      {process.env.NODE_ENV === 'development' && (
        <pre className="error-stack">{error.stack}</pre>
      )} */}

      {/* <p className="error-actions">
        <button onClick={() => window.location.reload()} className="error-button">
          Try Again
        </button>
      </p> */}
      <img src={errorImg} alt="error" />
    </div>
  );
};

export default ErrorPage;
