import React from "react";
import "./AryanStyla.css"
import skills from "../../assets/imgAryan/data/skills.json";

const GymansticsAcademyInVikasPuri = () => {
  return (
    <>
      <div className="container skills  ">
        {/* <h1 className=' font-bold text-[4vh] '></h1> */}
        <div className="items ">
          {skills.map((data) => (
            <>  
              <div
                className="item sm:grid-cols-1  "
                key={data.id}
                data-aos="flip-left"
                data-aos-duration="1000"
              >
                <img src={`/aryanImg/${data.imageSrc}`} alt="" />
                <h3>{data.title}</h3>
              </div>
            </>
          ))}
        </div>
      </div>
    </>
  );
};

export default GymansticsAcademyInVikasPuri;
