import React from "react";
import fb from "../../assets/images/facebook.png"
import yt from "../../assets/images/youtube (1).png"
import inst from "../../assets/images/instagram.png"
// import logo from "../../assets/images/logo.png"
import "./ContactStyla.css"


const Top10WeddingChoreographerInDelhi = () => {
  return (
    <div id="contact" className="flex items-center justify-center content-center justify-items-center relative">
      <div className="sm:flex-col sm:py-10 bottom-14 h-full flex justify-evenly bg-darkBlue w-4/5 py-24 rounded-xl translate-y-32 duration-700">
        <div>
          <h2 className="sm:text-2xl w-full text-4xl pt-4 pb-4 font-bold font-inter no-underline align-middle tracking-wide normal-case leading-none text-white pl-8">
          Address:
          </h2>
          <p className="sm:text-lg block font-inter font-light no-underline align-middle tracking-wide normal-case leading-none text-white text-xl pl-8 ">
             A/136, Shankar Garden, Vikas Puri, New Delhi -110018 
             <h2 className="sm:text-2xl w-full ml-0 text-4xl pt-4 pb-4 font-bold font-inter no-underline align-middle tracking-wide normal-case leading-none text-white pl-8">
            Contact Us
          </h2>
           
           {/* <a className=" hover:text-blue-500 text-white" href="http://">www.aonenatrajacademy.com </a> / */}
            Mail ID: bombayaryan@gmail.com
          </p>
        
          <h2 className="sm:text-2xl w-full text-4xl pt-4 pb-4 font-bold font-inter no-underline align-middle tracking-wide normal-case leading-none text-white pl-8">
            8587906794, 8766319829
          </h2>
        </div>
        <div className="sm:flex-col flex items-center justify-center content-center">

          {/* <div className="pb-4 pt-4 w-64 text-white">
            <Input
              name="email"
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="text-white"
              label="Email"
            />
         
          </div> */}

                        <div className="social-group">
                        <h2 className="sm:text-2xl w-full text-4xl pt-4 pb-4 font-bold font-inter no-underline align-middle tracking-wide normal-case leading-none text-white pl-8">
           Media
          </h2>
          <div className="icons flex align-middle justify-center mt-10 ml-5 gap-8 ">
                            <a href="https://www.youtube.com/@bombayaryan">
                            <img className=" w-10" src={yt} alt="" />
                            </a>
                            <a href="https://www.instagram.com/aonenatraj7?igsh=eGx1djR3NzU1bXhi&utm_source=qr">  <img className=" w-10" src={inst} alt="" /></a>
                            <a href="https://www.facebook.com/aonenatrajacademy?mibextid=JRoKGi"><img className=" w-10" src={fb} alt="" /></a>
                            {/* <a href="https://www.youtube.com/@bombayaryan"><img className=" px-2 w-10" src={logo} alt="" /></a>  */}
                            </div>
                        </div>

          <div>
            {/* <Button onClick={handleEmail} ripple={true} className="ml-2 ">
              Button
            </Button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Top10WeddingChoreographerInDelhi;
