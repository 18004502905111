import { motion } from 'framer-motion';
import React, { useContext, useEffect } from 'react'
import { ThemeBgContext } from "../ContextWrapper/ThemeContext";

import { weddingCardData } from "../../DataForPage/dummyData";
import Aos from "aos";
import "aos/dist/aos.css"


import {
  Card,
  CardBody,
  CardFooter,
  Typography,
} from "@material-tailwind/react";



function BestWeddingDanceChoreographerInDelhi() {
  const { theme } = useContext(ThemeBgContext);

  useEffect(() => {
    Aos.init();

  }, [])

  return (
    <>
           <div id='top' className="text-center mt-28 ">
                <h2
                  className={
                    theme === "light"
                      ? "sm:text-5xl text-dark text-6xl font-bold pt-20 no-underline font-inter align-middle tracking-wide normal-case leading-none cursor-pointer"
                      : "sm:text-5xl text-white text-6xl font-bold pt-20 no-underline font-inter align-middle tracking-wide normal-case leading-none cursor-pointer"
                  }
                >
                  Wedding Choreography
                </h2>
                <p
                  className={
                    theme === "light"
                      ? "sm:text-xl text-dark w-full px-20   text-2xl font-normal pt-10 no-underline font-inter align-middle tracking-wide normal-case leading-none cursor-pointer"
                      : "sm:text-xl  text-white w-full px-20  text-2xl font-normal pt-10 no-underline font-inter align-middle tracking-wide normal-case leading-none cursor-pointer"
                  }
                >
                 Lorem ipsum dolor sit amet consectetur adipisicing elit. Delectus alias ab, qui eum quae repellat. Blanditiis ea eos quia molestias sed, harum exercitationem aliquam corporis ad voluptatem rerum, ab porro.  </p>

              </div>
      <div className=" sm:grid-cols-1 grid grid-cols-1 items-center gap-2 content-between w-full pt-32">

        {weddingCardData.map((item) => {
          return (
            <motion.div
              className="sm:pl-0 mx-auto pl-20"
              key={item.id}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 1.5 }}
              variants={{
                visible: { opacity: 1, scale: 1 },
                hidden: { opacity: 0, scale: 0 },
              }}
            >
              <Card
                className={
                  item.id === "1"
                    ? " p-10 text-center  my-5 grid grid-cols-2  justify-items-center items-center bg-dark border border-white"
                    : " p-10 text-center  my-5 grid grid-cols-2  justify-items-center items-center bg-white border-black"
                }
              >
                <CardBody className="text-center">
                  <Typography
                    variant="h5"
                    className={
                      item.id === "1"
                        ? "mb-2 fonm-inter leading-normal no-underline align-middle tracking-wide normal-case text-white"
                        : "mb-2 fonm-inter leading-normal no-underline align-middle tracking-wide normal-case text-dark"
                    }
                  >
                    {item.title}
                  </Typography>
                  <Typography
                    className={
                      item.id === "1"
                        ? "mb-2 fonm-inter font-light leading-normal no-underline align-middle tracking-wide normal-case h-32 text-white"
                        : "mb-2 fonm-inter font-light  leading-normal no-underline align-middle tracking-wide normal-case h-32 text-dark"
                    }
                  >

                  </Typography>
                </CardBody>
                <CardFooter
                  divider
                  className="flex mt-6 flex-col items-center content-center justify-center py-3"
                >
                  <Typography
                    variant="small"
                    className={
                      item.id === "1"
                        ? "text-center mt-2 px-5 font-inter font-light leading-normal no-underline align-middle hover:animate-pulse tracking-wide cursor-pointer text-white"
                        : "text-center mt-2 px-5 font-inter font-light leading-normal no-underline align-middle hover:animate-pulse tracking-wide cursor-pointer text-dark"
                    }
                  >
                    {item.text}
                  </Typography>
                </CardFooter>
              </Card>
              
            </motion.div>
          );
        })}
      </div>

      <div>
      </div>
    </>
  )
}

export default BestWeddingDanceChoreographerInDelhi